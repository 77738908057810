.shadow{
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.05);
}
.transition{
  transition: .3s ease;
}

.overlay {
  &:before {
    content: '';
    background: rgba(125, 113, 211, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

a:focus, a:hover {
  color: $primary-color;
  text-decoration: none;
}

.bg-1{
  background: url(../images/backgrounds/banner-bg.jpg) no-repeat;
  background-size: cover; 
}

.bg-2{
  background: url(../images/backgrounds/elipse-bg.png) no-repeat;
  background-size: cover; 
}

.bg-3{
  background: url(../images/backgrounds/desk-bg.jpg) fixed no-repeat;
  background-size: cover; 
}

.bg-banner-2{
  background: url(../images/backgrounds/banner-bg-two.jpg) no-repeat;
  background-size: cover; 
}

.bg-elipse{
  background: url(../images/backgrounds/elipse-bg-three.png) no-repeat;
  background-position: center;
}

.bg-elipse-red{
  background: url(../images/backgrounds/elipse-bg-two.png) no-repeat;
  background-position: center;
}

.bg-elipse-half{
  background: url(../images/backgrounds/elipse-bg-three.png) no-repeat;
  background-position: center;
}

.bg-shape{
  background: url(../images/backgrounds/shape-bg.png) no-repeat;
  background-position: center;
}

.bg-primary-shape{
  background: url(../images/backgrounds/shape-overlay-bg.jpg) no-repeat;
  background-size: cover; 
  background-position: center;
}

.bg-shape-two{
  background: url(../images/backgrounds/shape-02-bg.png) no-repeat;
  background-position: center;
}


.bg-gray{
	background:  #f8f8f8;
}

.bg-blue{
  background: $primary-color;
}

.bg-coming-soon{
  background: url(../images/background/comming-soon.png) fixed no-repeat;
  background-size: cover;
  background-position: bottom;  
}
.section{
	padding: 100px 0;
}

.section-title{
	text-align: center;
  h2{
    font-size: 48px;
    font-family: $secondary-font;
    font-weight: 300;
    margin-bottom: 30px;
    font-weight: 300;
  }
  p{
    width: 70%;
    margin: 0 auto;
    font-size: 16px;
    line-height: 30px;
    @include mobile {
      width: 100%;
    }
  }
  margin-bottom: 85px;
}

.page-title{
  text-align: center;
}
// Promo Video
.video{
  position:relative;
  &:before{
    border-radius: 3px;
  }
  img{
    width: 100%;
    border-radius: 8px;
  }
  .video-button{
    position: absolute;
      left: 0;
      top: 0;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 100%;
      height: 100%;
  }
  .video-box{
    a{
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      .icon{
        height: 130px;
        width: 130px;
        position: relative;
        &:before{
          position: absolute;
          content: '';
          height: 100px;
          width: 100px;
          border-radius: 100%;
          background: rgba(125, 113, 211, 0.7);
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
        }
        &:after{
          position: absolute;
          top:0;
          content: '';
          height: 130px;
          width: 130px;
          border-radius: 100%;
          background: rgba(125, 113, 211, 0.5);
        }
        i{
          position: relative;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          z-index: 500;
          display: block;
          height: 70px;
          width: 70px;
          font-size: 35px;
          background: $primary-color;
          border-radius: 100%;
          color: $light;
          line-height: 70px;
          text-align: center;
        }
      }
      iframe{
        width: 100%;
        height: 100%;
      }
    }
  }
}

.form-control{
  &.main{
    background: $light;
    padding: 20px 20px;
    border: none;
    font-size: 14px;
    border-radius: 4px
  }
}

.input-group{
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  .input-group-addon{
    padding-left: 35px;
    padding-right: 35px;
    background: $primary-color;
    color: $light;
    cursor: pointer;
  }
}

.form-control::-webkit-input-placeholder { 
    color: $text-color;
    font-size: 14px;
 }

.left{
  overflow: hidden;
  img{
    margin-left: -40px;
    @include tablet {
      margin-left: 0;
      margin-bottom: 30px;

    } 
  }
}
.right{
  overflow: hidden;
  img{
    margin-left: 40px;
    @include tablet {
      margin-left: 0;
    }
  }
}

.hide-overflow{
  overflow: hidden;
}

.nav-up{
  top: -70px;
}

.mb-md-30{
  @include tablet {
    margin-bottom: 30px;
  }
}
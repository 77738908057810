.testimonial-slider{
	.testimonial-item{
		background: $light;
		display: flex;
		border-radius: 6px;
		.content{
			flex-basis: 75%;
			padding: 40px 30px;
			.name{
				h5{
					font-weight: bold;
					text-transform: uppercase;
				}
			}
			.pos-in-com{
				span{
					color: $primary-color;
				}
				margin-bottom: 15px;
			}
			.speech{
				p{
					line-height: 30px;
				}
				margin-bottom: 15px;
			}
		}
		.image{
			display: flex;
			img{
				align-self: flex-end;
			}
		}
	}
	.slick-prev{
		left: -100px;
	}
	.slick-next{
		right: -100px;
	}
	.slick-prev, .slick-next{
		background: $light;
		width: 65px;
		height: 55px;
		border-radius: 4px;
		&:hover{
			&:before{
				color: $primary-color;
			}
		}
		&:before {
			color: $text-color;
		}		
	}
}
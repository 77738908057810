// Variables
$body-color: #ffffff;
$primary-color: #7d71d3;
$accent-color: #ff698d;
$border-color:#cccccc;
$primary-font: 'Open Sans', sans-serif;
$secondary-font: 'Josefin Sans', sans-serif;
$text-color: #999999;
$title-color: #5e5e5e;
$gray: #f8f8f8;
$light-gray: #fafafa;
$dark: #000;
$light: #fff;
$black: #000;
$icon-font: 'themefisher-font';
